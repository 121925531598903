import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getsensor_dag_plaats, getsensor_maand_plaats, getsensor_uur_plaats, periode, getaccommodatie } from "../../Standplaatsen/Reducers/actions";
import { fixmessage, message } from "../../../Reducers/loading/actions";
import { useParams } from "react-router-dom";
import moment from "moment";
import BarGraph from "./Components/graphelectra";
import { Link } from "react-router-dom";
import Tabel from "./Components/tabelelectraacco";
import FilterSensor from "./Components/filterelectAcco";
import Loading from "../../../Components/loading/loading";
import Periode from "./Components/periode";
const GastverbruikPage = (props) => {
  const [load, Setload] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [[startdate, enddate], Setstartdate] = useState([]);

  const [datum_hour, Setdatum_hour] = useState({
    startdate: moment().subtract(1, "days").toDate(),
    enddate: new Date(),
  });
  const [gemiddelde, Setgemiddelde] = useState([]);
  const [stand, setStand] = useState([]);
  const [standdag, setStandDag] = useState([]);
  const [max, Setmax] = useState([]);

  const params = useParams();
  useEffect(() => {
    getvals()
      .then((data) => getvals_dag(data))
      .then((data) => getvals_uur(data))
      .then(() => Setload(2))
      .catch((error) => {
        Setload(1);
        console.log(error);
        props.message(4000, "warning", error.message); // Toon een foutmelding aan de gebruiker
      });
  }, [props.perioden]);

  function getvals() {
    return new Promise((resolve, reject) => {
      props.getaccommodatie(params.id).then((data) => {
        if (data.results) {
          data.results.aankomst = props.perioden.startdate;
          data.results.vertrek = props.perioden.enddate;
          Setstartdate([props.perioden.startdate, props.perioden.enddate]);

          resolve(data.results);
        } else {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        }
      });
    });
  }

  function getvals_dag(data) {
    return new Promise((resolve, reject) => {
      props
        .getsensor_dag_plaats(data._id, {
          startdate: moment(data.aankomst).toDate(),
          enddate: moment(data.vertrek).toDate(),
        })
        .then((response) => {
          if (!response.results || response.results.length === 0) {
            const errorMessage = "Er zijn geen gegevens gevonden voor deze periode";
            props.fixmessage("warning", errorMessage);
            reject(new Error(errorMessage));
          } else {
            const gemData = [];
            const maxData = [];
            const standDagData = [];
            const standData = [];

            response.results.forEach((meter) => {
              const values = meter.data.map((element) => element.value);
              const gemiddelde = values.reduce((a, b) => a + b, 0) / values.length;
              const max = Math.max(...values);
              const standDag = values.reduce((acc, curr) => acc + curr, 0).toFixed(2);
              gemData.push({ sensor_id: meter._id, value: gemiddelde });
              maxData.push({ sensor_id: meter._id, value: max });
              standDagData.push({ sensor_id: meter._id, value: standDag });
              standData.push({ sensor_id: meter._id, value: values[values.length - 1] });
            });
            Setgemiddelde(gemData);
            setStand(standData);
            setStandDag(standDagData);
            Setmax(maxData);
            resolve(data);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        });
    });
  }

  function getvals_uur(data) {
    return new Promise((resolve, reject) => {
      props.getsensor_uur_plaats(data._id, datum_hour).then((data) => {
        if (!data.results) {
          reject(new Error("Er is een fout opgetreden bij het laden van de sensoren"));
        } else {
          if (data.results.data && data.results.data[0]) setStand(data.results.data[0].value.toFixed(2));
          resolve();
        }
      });
    });
  }
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getMeterIcon = (meter, size) => {
    switch (meter) {
      case "water":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-droplet"></i>;
      case "gas":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-fire"></i>;
      case "electra":
        return <i style={{ fontSize: size + "rem", color: "#d96a29" }} className="fa-solid fa-bolt"></i>;
      default:
        return null;
    }
  };
  const changedata = ({ startdate, enddate }) => {
    Setstartdate([startdate, enddate]);
    props.periode({ startdate, enddate });
  };
  const { accommodatie, perioden } = props;

  if (load > 0) {
    return (
      <div className="box box-default show-elecra">
        <div className="box-header subheader ">
          <h3 className="box-title">
            <strong>{accommodatie.naam} </strong>
          </h3>

          <div className="box-tools pull-right">
            <Periode changedata={changedata} />

            <Link className="btn btn-warning btn-sm ms-2" role="group" to={"/accommodatie/edit/" + accommodatie._id}>
              Wijzigen
            </Link>
          </div>
        </div>
        {load > 1 && (
          <div className="box box-default">
            <div className="box-header with-border">
              {accommodatie.accommodatie.meters.map &&
                accommodatie.accommodatie.meters.map((element, index) => (
                  <div className="mb-4 pb-2 border-bottom">
                    <h3>
                      {getMeterIcon(element.soort, 2)} {capitalizeFirstLetter(element.soort)} meter
                    </h3>
                    <FilterSensor
                      accommodatie={accommodatie}
                      verbruik={standdag.find((item) => item.sensor_id === element.sensor_id)?.value}
                      gemiddelde={gemiddelde.find((item) => item.sensor_id === element.sensor_id)?.value ?? 0}
                      max={max.find((item) => item.sensor_id === element.sensor_id)?.value ?? 0}
                      stand={stand.find((item) => item.sensor_id === element.sensor_id)?.value ?? 0}
                      index={index}
                      soort={element.soort}
                      element={element}
                      loading={load}
                    />
                  </div>
                ))}
            </div>
            <div className="box-primary mx-4">
              <div className="box-body" />
              <ul className="nav nav-tabs">
                {accommodatie.accommodatie.meters.map &&
                  accommodatie.accommodatie.meters.map((element, index) => (
                    <li className="nav-item" key={index}>
                      <a className={`nav-link ${activeTab === index ? "active" : ""}`} onClick={() => handleTabClick(index)}>
                        {getMeterIcon(element.soort, 1.5)} {capitalizeFirstLetter(element.soort)} meter
                      </a>
                    </li>
                  ))}
              </ul>
              <div className="tab-content">
                {accommodatie.accommodatie.meters.map &&
                  accommodatie.accommodatie.meters.map((element, index) => (
                    <div key={index} className={`tab-pane ${activeTab === index ? "active" : ""} p-2`}>
                      <div className="row p-2">
                        <div className="col-12 col-md-10 col-lg-12">
                          <Tabel categorie={element.categorie} sensor={accommodatie.dag.find((item) => item._id === element.sensor_id)} />
                        </div>
                        <div className="col-12">
                          <BarGraph sensor={accommodatie.dag.find((item) => item._id === element.sensor_id)?.data || []} kleur="#B1BF41" type="dag" />
                        </div>

                        <div className="col-12 col-md-12 col-lg-12">
                          <div className="box-titel">
                            <h3 className="p-4">Metingen 24 uur</h3>
                          </div>

                          <BarGraph sensor={accommodatie.uur.find((item) => item._id === element.sensor_id)?.data || []} kleur="#D96A29" type="uur" />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <Loading loadings={load} />;
  }
};

function mapStateToProps(state) {
  return { sensor: state.sensoren, accommodatie: state.standplaatsen, gastverbruik: state.gastenverbruik.gastverbruik, perioden: state.standplaatsen.periode };
}

export default connect(mapStateToProps, { fixmessage, getsensor_dag_plaats, getsensor_maand_plaats, getsensor_uur_plaats, periode, message, getaccommodatie })(GastverbruikPage);
